import React, { FC, useEffect } from 'react'
import styled, { css } from 'styled-components'

import Head from '../../../layouts/Head'
import { isInvalidStateError, isUserBlockedError, useAuth } from '../../../lib/auth'
import { LoginError } from '../../auth/LoginError'
import Layout from '../../global/Layout'

const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.component.backgroundDarkBlue};
    padding: 30px;
    width: 100vw;
    height: 100vh;
    color: ${theme.colors.grayscale.white};
  `}
`

export const LoginPage: FC = () => {
  const { error, loginWithRedirect } = useAuth()
  const isInvalidState = isInvalidStateError(error)
  const isUserBlocked = isUserBlockedError(error)

  useEffect(() => {
    if (isUserBlocked) {
      return
    }
    ;(async (): Promise<void> => {
      await loginWithRedirect()
    })()
  }, [isUserBlocked, loginWithRedirect])

  if (error && !isInvalidState) {
    return (
      <>
        <Head title="Login error" />
        <Layout>
          <LoginError error={error} />
        </Layout>
      </>
    )
  }

  return (
    <>
      <Head title="Redirecting to login..." />
      <Container>
        <p>Redirecting to login...</p>
      </Container>
    </>
  )
}
